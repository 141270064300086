<template>
  <div class="page page-statistics">
    <h1 class="page__title">
      Статистика
    </h1>

    <iframe
      v-if="isAuth"
      class="page-statistics__iframe"
      src="https://demobox7.rt.ru/share/dashboard/8?s_switch=1&s_list=west"
      frameborder="0"
      @load="onLoad"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Statistics',

  data() {
    return {
      isAuth: false,
    };
  },

  methods: {
    ...mapActions('system', ['setLoading']),
    async authInMetrica() {
      try {
        this.setLoading({ key: 'page', value: true });

        await this.$API.metrica.auth();

        this.isAuth = true;
      } catch (e) {
        this.$EsmpNotify.$show('Возникла проблема с загрузкой счетчиков. Обратитесь к администратору', 'error');

        console.error(e);
      }
    },

    onLoad() {
      this.setLoading({ key: 'page', value: false });
    },
  },

  created() {
    this.authInMetrica();
  },
};
</script>

<style lang="scss">
.page-statistics {
  display: flex;
  flex-direction: column;

  &__iframe {
    flex-grow: 1;
  }
}
</style>
